<template>
    <section class="content">
        <!-- DATA FISIK SISWA & DATA DINAS -->
        <form role="form" @submit="submitForm">
        <div class="col-12">
        <router-link :to="{ path: '/siswa-monitoring' }" class="btn btn-secondary mb-2 mt-2"> <i class='fas fa-arrow-circle-left'></i>&nbsp;Kembali Ke Siswa Monitoring</router-link>
        <div class="card card-primary">
            <div class="card-header">
            <h3 class="card-title">DATA FISIK SISWA & DATA KEDINASAN</h3>

            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
                </button>
            </div>
            </div>
            <div class="card-body" style="display: block;">
            <div class="row">
            <div class="col-9">
                <div class="col-md-12 form-group">
                    <label class="control-label">NAMA</label>
                    <input
                    id="nama"
                    class="form-control"
                    v-model="form.nama_siswa"
                    type="text"
                    required="required"
                    name="nama"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">PROGRAM</label>
                    <input
                    id="program"
                    class="form-control"
                    v-model="form.program"
                    type="text"
                    required="required"
                    name="program"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">TINGGI BADAN</label>
                    <input
                    id="fisik_tinggi"
                    class="form-control"
                    v-model="form.fisik_tinggi"
                    type="text"
                    required="required"
                    name="fisik_tinggi"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">BERAT BADAN</label>
                    <input
                    id="fisik_berat"
                    class="form-control"
                    v-model="form.fisik_berat"
                    type="text"
                    required="required"
                    name="fisik_berat"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">KONDISI MATA (KANAN & KIRI)</label>
                    <input
                    id="fisik_mata"
                    class="form-control"
                    v-model="form.fisik_mata"
                    type="text"
                    required="required"
                    name="fisik_mata"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">RIWAYAT KELAINAN</label>
                    <input
                    id="fisik_kelainan"
                    class="form-control"
                    v-model="form.fisik_kelainan"
                    type="text"
                    required="required"
                    name="fisik_kelainan"
                    />
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">RIWAYAT PENYAKIT</label>
                    <input
                    id="fisik_penyakit"
                    class="form-control"
                    v-model="form.fisik_penyakit"
                    type="text"
                    required="required"
                    name="fisik_penyakit"
                    />
                </div>
            </div>
            <div class="col-3">
                <div class="card-body p-1 user-profile">
                    <img
                    style="object-fit: cover; height: 240px; width: 100%"
                    alt="image"
                    id="imgPreview"
                    class="img-fluid img-responsive"
                    :src="url + '/uploads/default/unknown_user.jpg'"
                    v-if="!form.img_code"
                    />
                    <img
                    style="object-fit: cover; height: 240px; width: 100%"
                    alt="image"
                    id="imgPreview"
                    class="img-fluid img-responsive"
                    :src="url + '/file/image/' + form.img_code"
                    v-if="form.img_code"
                    />
                </div>
            </div>
            <div class="col-lg-12">
                <div class="callout callout-warning">
                    <h6>
                    <strong>Catatan: </strong> Data tahapan tes setiap sekolah kedinasan akan ditampilkan setelah dinas dipilih & disimpan.
                    </h6>
                </div>
            </div>
            </div>
            <hr>
            <div class="row">
            <div class="col-12 mt-3">
            <div class="text-right">
                <button type="button" id="dinas-btn" v-on:click="addItem" data-action="create" class="btn btn-primary" title="Buat Baru">
                <i class="fas fas fa-plus"></i> Tambah Dinas
                </button>
            </div>
            </div>
            </div>
            <table class="table table-hover" id="tbldinas" ref="tbldinas">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>ID DINAS</th>
                    <th>TUJUAN DINAS</th>
                    <th>TINDAKAN</th>
                </tr>
                </thead>
                <tbody @click="handleClick"></tbody>
            </table>
            </div>
            <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-1">Simpan</button>
            </div>
            <!-- /.card-body -->
        </div>
        </div>
        </form>
        
        <!-- DATA DOKUMEN -->
        <div class="col-12">
        <div class="card card-primary">
            <div class="card-header">
            <h3 class="card-title">DATA DOKUMEN SISWA</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
                </button>
            </div>
            </div>
            <div class="card-body" style="display: block;">
            <div class="row">
            <div class="col-12 mt-3">
            <div class="text-right">
                <button type="button" v-on:click="addDoc" data-action="create" class="btn btn-primary" title="Buat Baru">
                <i class="fas fas fa-plus"></i> Tambah Dokumen
                </button>
            </div>
            </div>
            </div>
            <table class="table table-hover" ref="tbldoc">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>FILENAME</th>
                    <th>TINDAKAN</th>
                </tr>
                </thead>
                <tbody @click="handleClick2"></tbody>
            </table>
            </div>
        </div>
        </div>
        
        <!-- DATA MONITORING TES -->
        <div class="col-12">
        <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">DATA MONITORING TES</h3>
            <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
            </button>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsivex">
            <table class="table table-hover" ref="tblmonitoringtes">
            <thead>
                <tr>
                <th style="width:70px;">TUJUAN DINAS</th>
                <th v-for="n in 15" style="width:80px; padding: 10px;">TAHAP KE-{{n}}</th>
                </tr>
            </thead>
                <tbody @click="handleClick3" class="tes">
                </tbody>
            </table>
            </div>
        </div>
        </div>
        </div>

        <!-- FORM DOKUMEN (UPLOAD 1 PER 1 FILE) -->
        <div class="modal" tabindex="-1" role="dialog" ref="formDoc" data-backdrop="static">
        <div class="modal-dialog" role="document">
        <div class="modal-content">
        <form role="form" @submit.prevent="saveDoc">
            <input id="doc_mcu" class="form-control" v-model="formDoc.doc_mcu" type="hidden" name="doc_mcu" />
            <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="bg-secondary p-3 mb-3 d-none">
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                    <label class="control-label">Jenis Dokumen</label>
                    <select
                        v-model="formDoc.doctype_id"
                        id="doctype_id"
                        name="doctype_id"
                        class="form-control">
                        <option
                        v-for="doc in groups"
                        v-bind:key="doc.doctype_id"
                        v-bind:value="doc.doctype_id"
                        >
                        {{ doc.label }}
                        </option>
                    </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="inputFile">Upload Dokumen Medical Check Up</label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                            <label class="custom-file-label" for="inputFile">Silahkan upload gambar</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">
                        Simpan
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        Tutup
                </button>
            </div>
        </form>
        </div>
    </div>
        </div>

        <!-- FORM PILIH DINAS -->
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <form role="form" @submit.prevent="saveItem">
                <div class="modal-header">
                    <h4 class="modal-title"></h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="bg-secondary p-3 mb-3 d-none">
                    </div>
                    <div class="row">
                        <input type="hidden" name="id" id="id" v-model="formItem.id" />
                    <div class="col-md-12 form-group">
                        <label class="control-label">Tujuan Dinas</label>
                        <v-select :options="optDinas" v-model="formItem.dinas_id" :reduce="opt => opt.id" @input="setSelected" />
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" id="append" @click="submitItemType='append'" class="btn btn-primary">
                        Tambah item
                    </button>
                    <button type="submit" id="update" @click="submitItemType='update'" class="btn btn-default">
                        Update item
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        Tutup
                    </button>
                </div>
            </form>
            </div>
        </div>
        </div>

        <!-- FORM DATA TAHAPAN TES -->
        <div class="modal" tabindex="-1" role="dialog" ref="formTes" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <form role="form" @submit.prevent="saveTes">
                <div class="modal-header">
                    <h4 class="modal-title"></h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="bg-secondary p-3 mb-3 d-none">
                    </div>
                    <div class="row">
                    <input type="hidden" name="id" id="id" v-model="formTes.id" />
                    <input type="hidden" name="sekolah_id" id="sekolah_id" v-model="formTes.sekolah_id" />
                    <input type="hidden" name="siswa_id" id="siswa_id" v-model="formTes.siswa_id" />
                    <input type="hidden" name="index" id="index" v-model="formTes.index" />
                    <div class="col-md-12 form-group">
                    <label class="control-label">JUDUL</label>
                    <input
                        id="title"
                        class="form-control"
                        v-model="formTes.title"
                        type="text"
                        name="title"
                        required
                    />
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">NOTE TAHAP TES</label>
                    <textarea class="form-control" v-model="formTes.note_tahaptes" rows="7" cols="50"></textarea>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">TANGGAL</label>
                    <datepicker v-model="formTes.date_tahaptes" id="date_tahaptes" />
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">NILAI TES</label>
                    <input
                        id="nilai_tes"
                        class="form-control"
                        v-model="formTes.nilai_tes"
                        type="number"
                        name="nilai_tes"
                    />
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">NOTE TES</label>
                    <textarea class="form-control" v-model="formTes.note_tes" rows="7" cols="50"></textarea>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label"></label>
                    <input
                        id="is_lulus"
                        v-model="formTes.is_lulus"
                        type="checkbox"
                        name="is_lulus"
                    />
                    <label for="is_lulus">&nbsp;LULUS</label>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">NOTE LULUS</label>
                    <textarea class="form-control" v-model="formTes.note_lulus" rows="3" cols="50"></textarea>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">CATATAN INTERNAL</label>
                    <textarea class="form-control" v-model="formTes.catatan_internal" rows="3" cols="50"></textarea>
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" id="submit" class="btn btn-primary">
                        Simpan
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        Tutup
                    </button>
                </div>
            </form>
            </div>
        </div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import datepicker from "@/components/Datepicker";
import Swal from "sweetalert2";
import $ from "jquery";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "icheck-material";
import moment from "moment";

export default {
	name: "",
    data() {
    return {
        errors: [],
        method: "POST",
        roles: "",
			url: "",
			optDinas: [],
			groups: [],
        formTitle: "Form Perkembangan Siswa",
        form: {
        fisik_tinggi: "",
        fisik_berat: "",
        fisik_mata: "",
        fisik_kelainan: "",
        fisik_penyakit: "",
        img_code: "",
        },
        formItem: {
            id: "",
            dinas_id: "",
        },
        formTes: {
            id: "",
            siswa_id: "",
            sekolah_id: "",
            index: "",
            title: "",
            note_tahaptes: "",
            date_tahaptes: "",
            nilai_tes: "",
            note_tes: "",
            is_lulus: "",
            note_lulus: "",
            catatan_internal: "",
        },
        formDoc: {
            doc_mcu: "",
            doctype_id: "",
        },
        form_data: "",
        cek_monitoring: "",
        cek_dinas: "",
    };
    },
    created: function () {
        this.url = window.$apiUrl;
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);

        this.loadDetail(this.$route.params.id);
        authFetch('/akademik/siswa_monitoring/dinas')
        .then(res => {
            if (res.status === 201) {
            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {
            this.optDinas = js.data;
        });

        authFetch('/akademik/siswa_monitoring/jenis_doc')
        .then(res => {
            if (res.status === 201) {
            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {
            this.groups = js.data;
        });

        //cek data monitoring tes
        authFetch('/akademik/siswa_monitoring/count_monitoring_tes/'+this.$route.params.id)
        .then(res => {
            if (res.status === 201) {
            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {
            this.cek_monitoring = js;
        });
    },
	components: {
        vSelect,
        datepicker,
    },
    computed: {
        // cekDinas(){
        //     var data = this.table3.api().ajax.reload();
        //     this.cek_dinas = data.count();

        //     if (this.cek_dinas > 3) {
        //         $('#dinas-btn').prop('disabled', true);
        //     }
        // },
    },
	methods: {
    handleClick(e) {
        const evt = this.$refs;
        if (e.target.matches('.link-role')) {
        this.$router.push({ path: '/permit/' + e.target.dataset.id })
        return false;
        }

		if (e.target.closest("button")) {
        var btnEl = $(e.target).closest("button").get(0);
            if (btnEl.dataset.action == "remove") {
            var id = btnEl.dataset.id;
            if(!id)
            {
                var nRow = $(e.target).closest("tr").get(0);
                this.tbl.fnDeleteRow(nRow);
            } else {
            Swal.fire({
            title: "Hapus pilihan dinas?",
            text: "Perubahan dinas mengharuskan entri ulang data tahapan tes sesuai dinas yang dihapus.",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            }).then((result) => {
            if (result.isDenied) {
            authFetch('/akademik/siswa_monitoring/detail/'+id, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: 'id='+id,
            })  .then((res) => {
                return res.json();
                }).then((js) => {
                if(js.success)
                {
                    var nRow = $(e.target).closest("tr").get(0);
                    this.tbl.fnDeleteRow(nRow);
                    this.tbl.api().ajax.reload();
                    this.table3.api().ajax.reload();
                }
                });
            }
            });
        }
        } else if (e.target.dataset.action == "edit") {
            var nRow = $(e.target).closest("tr").get(0);
            var iRow = this.tbl.fnGetPosition(nRow);
            var aData = this.tbl.fnGetData(iRow);

            this.formItem = this.getDataItem(aData);
            this.formItem.nRow = nRow;
            $("#append").hide();
            $("#update").show();
            $(evt.formDialog).modal("show");
        }
        return false;
			}
    },
    handleClick2(e) {
        const evt = this.$refs;
        if (e.target.matches('.link-role')) {
        this.$router.push({ path: '/permit/' + e.target.dataset.id })
        return false;
        }

        if (e.target.closest("button")) {
            var btnEl = $(e.target).closest("button").get(0);
            if (btnEl.dataset.action == "remove") {
                var id = btnEl.dataset.id;
                if(!id)
                {
                    var nRow = $(e.target).closest("tr").get(0);
                    this.tbl.fnDeleteRow(nRow);
                } else {
                    Swal.fire({
                    title: "Hapus dokumen?",
                    icon: "question",
                    denyButtonText: '<i class="fa fa-times"></i> Hapus',
                    showCancelButton: true,
                    showDenyButton: true,
                    showConfirmButton: false,
                    }).then((result) => {
                    if (result.isDenied) {
                    authFetch('/akademik/siswa_monitoring/monitoring_doc/'+id, {
                        method: "DELETE",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: 'id='+id,
                    })  .then((res) => {
                        return res.json();
                        }).then((js) => {
                        if(js.success)
                        {
                            var nRow = $(e.target).closest("tr").get(0);
                            this.table.fnDeleteRow(nRow);
                            this.table.api().ajax.reload();
                        }
                        });
                    }
                    });
                }
            } else if (btnEl.dataset.action == "download") {
                var href = btnEl.dataset.href;
                this.download(href);
            } else if (btnEl.dataset.action == "preview") {
                var href = btnEl.dataset.href;
                this.preview(href);
            }
            return false;
        }
    },
    handleClick3(e) {
    const evt = this.$refs;

    if (e.target.closest("button")) {
    var btnEl = $(e.target).closest("button").get(0);
    if (btnEl.dataset.action == "remove") {
    var id = btnEl.dataset.id;
    if(!id)
    {
        var nRow = $(e.target).closest("tr").get(0);
        this.tbl.fnDeleteRow(nRow);
    } else {
    }
    } else if (btnEl.dataset.action == "detail") {
    // alert("id siswa " + btnEl.dataset.id + " dan id dinas " + btnEl.dataset.dinasid + " dan index ke " + btnEl.dataset.index);

    var data = '';
    var paramdata = { siswa_id: btnEl.dataset.id, dinas_id: btnEl.dataset.dinasid, index: btnEl.dataset.index };
        data = new URLSearchParams(paramdata);

    let url = '';
    url = "/akademik/siswa_monitoring/monitoring_tes_detail?" + data;

    authFetch(url)
    .then((res) => {
    if (res.status === 201) {
    } else if (res.status === 400) {
    }
    return res.json();
    })
    .then((js) => {
        this.formTes = js;
        this.formTes.is_lulus = js.is_lulus == "t" ? true : false;
        this.formTes.date_tahaptes = !js.date_tahaptes ? moment().format("DD/MM/YYYY") : moment(js.date_tahaptes).format("DD/MM/YYYY");
        $(evt.formTes).modal("show");
    });
    } else if (btnEl.dataset.action == "tambah") {
        this.$router.push("/dinas-tahapan-tes/" + btnEl.dataset.dinasid);
    }
    return false;
    }
    },
    download: function(data) {
        window.open(window.$apiUrl+data, "_blank");
    },
    preview: function(data) {
        window.open(window.$apiUrl+data, "_blank");
    },
    setSelected(value) {
      const self = this;
      self.formItem.dinas_id = value;
    },
    loadDetail: function(id){
        authFetch("/akademik/siswa_monitoring/detail_siswa/" + id).then((res) => {
            res.json().then((json) => {
            console.log(json);
            this.form = json;
            });
        });
    },
    urlForm(url, method, data){
		const e = this.$refs;
		Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
			authFetch(url, {
					method: method,
					body: data,
			})
				.then((res) => {
				return res.json();
				})
				.then((js) => {
				if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    $(e.formDoc).modal("hide");
                    this.table.api().ajax.reload();
                } else {
                    Swal.fire("Proses gagal", ``, "error");
                    }
				});
			}
		});
	},
	saveDoc: function(ev) {
        const e = this.$refs;
		const self = this;
        self.formDoc.id = self.$route.params.id;
		var data = Object.keys(self.formDoc).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(self.formDoc[key])).join('&');
		var urlSubmit = '/akademik/siswa_monitoring/monitoring_doc';
		if (self.method == 'PUT') urlSubmit = '/akademik/siswa_monitoring/monitoring_doc/' + self.formDoc.id;

		var file_data = $('#inputFile').prop('files');
		if (file_data.length == 0) {
			console.log("upload kosong");
			self.urlForm(urlSubmit, 'PUT', data);
		} else {
		self.doUpload().then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {}
			return res.json();
        }).then(js => {
			console.log(js)
			self.errors = [];
			if (!js.status) {
				console.log(js.details)
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						self.errors.push(js.details[key])
					}
				}
				return;
			} else {
				if (js.message[0]) {
					self.formDoc.doc_mcu = js.message[0].code;
				}
				var data2 = Object.keys(self.formDoc).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(self.formDoc[key])).join('&');
				self.urlForm(urlSubmit, 'PUT', data2);
			}
    	});
		}
        ev.preventDefault();
    },
    submitForm: function (ev) {
    var self = this;
    const e = this.$refs;
    
    var data = this.tbl.getSerialize();
    self.form.id = self.$route.params.id;
    var setmethod = self.form.id ? "PUT" : "POST";
    var items = [];
    this.tbl
    .api()
    .rows()
    .every(function(rowIdx, tableLoop, rowLoop) {
    var d = this.data();
    items.push(d);
    });

    var allData = { group: self.form, items: items };
    var urlSubmit = "/akademik/siswa_monitoring";
    if (self.method == "PUT") urlSubmit = "/akademik/siswa_monitoring/" + self.form.id;

    Swal.fire({
    title: "Simpan?",
    showCancelButton: true,
    confirmButtonText: `Ya`,
    cancelButtonText: "Tidak",
    }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
    authFetch(urlSubmit, {
        method: setmethod,
        headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
        },
        body: JSON.stringify(allData),
    })
    .then((res) => {
    return res.json();
    })
    .then((js) => {
    if (js.success) {
        Swal.fire("Proses Berhasil", ``, "success");
        $(e.formDialog).modal("hide");
        this.tbl.api().ajax.reload();
        this.table3.api().ajax.reload();
    } else {
        Swal.fire("Proses gagal", ``, "error");
    }
    });
    }
    });

    ev.preventDefault();
    },
    saveTes: function (ev) {
        const e = this.$refs;

        var data = Object.keys(this.formTes)
        .map(
        (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.formTes[key])
        )
        .join("&");
        var urlSubmit = '';
        // if (this.method == "PUT")
        urlSubmit = "/akademik/siswa_monitoring/monitoring_tes/" + this.formTes.id;

        Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
			authFetch(urlSubmit, {
					method: "PUT",
					body: data,
			})
            .then((res) => {
            return res.json();
            })
            .then((js) => {
            if (js.success) {
                Swal.fire("Proses Berhasil", ``, "success");
                $(e.formTes).modal("hide");
                this.table3.api().ajax.reload();
            } else {
                Swal.fire("Proses gagal", ``, "error");
            }
            });
			}
		});

        ev.preventDefault();
    },
    uploadFoto: function (file) {
        var file_data = $("#inputFile").prop("files");
        var form_data = new FormData();
        var fileInput = $("#inputFile")[0];
        $.each(fileInput.files, function (k, file) {
            form_data.append("file", file);
        });
        form_data.append("subdir", "/medical-checkup/");
        form_data.append("id", this.formDoc.id);
        this.form_data = form_data;
        return false;
    },
    doUpload: function () {
            return authFetch("/akademik/siswa_monitoring/upload_data", {
                method: "POST",
                body: this.form_data,
                headers: {
                    "Content-Type": null,
                },
            });
    },
    resetFormItem(){
        this.formItem = {
            id: "",
            dinas_id: "",
            nama_dinas: "",
        };
    },
    getFormItem(){
        var aData = {
            id: this.formItem.id,
            dinas_id: this.formItem.dinas_id
        };
        return aData;
    },
    getDataItem(aData){
        return {
            id: aData.id,
            dinas_id: aData.dinas_id,
            nama_dinas: aData.nama_dinas,
        };
    },
    addDoc: function() {
        const e = this.$refs;
        $(e.formDoc).modal("show");
    },
    addItem: function() {
      const e = this.$refs;
      this.resetFormItem();
      $("#update").hide();
      $("#append").show();
      $(e.formDialog).modal("show");
    },
    saveItem: function(ev) {
      var btnId = this.submitItemType;
      if(ev.submitter)
          btnId = ev.submitter.id
      ev.preventDefault();
      if (btnId == "append") {
          this.appendItem(ev);
      } else if (btnId == "update") {
          this.updateItem(ev);
      }
    },
    appendItem: function(ev) {
      const e = this.$refs;
			var newArray = this.optDinas.filter((el) => {
          return el.id == this.formItem.dinas_id;
      });
      var nameDinas = newArray[0];
      // if (!nameDinas) return;
      // this.errors = [];
      let nRow = this.tbl.fnAddRow(this.formItem);
      var aData = this.getFormItem();
			aData.nama_dinas = nameDinas.label;
      this.tbl.fnUpdate(aData, nRow);
      $("td .btn.edit", nRow).click();
      this.resetFormItem();
      $(e.formDialog).modal("hide");
    },
    updateItem: function(ev) {
      const e = this.$refs;
      // this.errors = [];
			var newArray = this.optDinas.filter((el) => {
          return el.id == this.formItem.dinas_id;
      });
      var nameDinas = newArray[0];
      if (!nameDinas) return;
      let nRow = this.formItem.nRow;
      var aData = this.getFormItem();
			aData.nama_dinas = nameDinas.label;
      this.tbl.fnUpdate(aData, nRow);
      $("td .btn.edit", nRow).click();
      this.resetFormItem();
      $(e.formDialog).modal("hide");
    },
	},
	mounted() {
    const e = this.$refs;
    var self = this;
		
    this.tbl = createTable(e.tbldinas, {
    title: "Pilihan Dinas",
    serverSide: false,
    language: {
        zeroRecords: "Belum ada data pilihan dinas."
    },
    ajax: "/akademik/siswa_monitoring/detail",
    paramData: function(d) {
        if (self.$route.params.id) {
            d.siswa_id = self.$route.params.id;
        } else {
            d.siswa_id = 0;
        }
    },
    selectedRowClass: "",
    frame: true,
    displayLength: 100,
    lengthChange: false,
    filter: false,
    columns: [
        { data: "id", visible: false },
        { data: "dinas_id", visible: false },
        { data: "nama_dinas" },
        { data: "action",
        render: function (data, type, row, meta) {
            return `<div class="btn-group">
        <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
        row.id +
        `"><i class="fas fa-trash"></i> Hapus</button>
        </div>`;
        /*<button type="button" class="btn btn-sm btn-outline-warning" data-action="edit" data-id="` +
        row.id +
        `"><i class="fas fa-edit"></i> Edit</button> */
        }, },
    ],
    rowCallback: function(row, data) {
    },
    });

    this.table = createTable(e.tbldoc, {
    title: "Dokumen Pendukung",
    serverSide: false,
    language: {
        zeroRecords: "Belum ada data dokumen."
    },
    ajax: "/akademik/siswa_monitoring/monitoring_doc",
    paramData: function(d) {
        if (self.$route.params.id) {
            d.siswa_id = self.$route.params.id;
        } else {
            d.siswa_id = 0;
        }
    },
    selectedRowClass: "",
    frame: true,
    displayLength: 100,
    lengthChange: false,
    filter: false,
    columns: [
        { data: "id", visible: false },
        { data: "file_name" },
        { data: "action",
        render: function (data, type, row, meta) {
            return `<div class="btn-group">
            <button type="button" class="btn btn-sm btn-outline-success" data-action="preview" data-href="/file/view/` + row.filecode +`"><i class="fas fa-eye"></i> Preview</button>
            <button type="button" class="btn btn-sm btn-outline-info" data-action="download" data-href="/file/download/` + row.filecode +`"><i class="fas fa-download" style="pointer-events:none"></i> Download</button>
            <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` + row.id +`"><i class="fas fa-trash"></i> Hapus</button>
            </div>`;
        }, },
    ],
    rowCallback: function(row, data) {
    },
    });

    this.table3 = createTable(e.tblmonitoringtes, {
        title: "Data Tahapan Tes",
        serverSide: false,
        language: {
            zeroRecords: "<h2>DATA TIDAK TERSEDIA. SILAHKAN PILIH KEDINASAN TERLEBIH DAHULU.</h2>"
        },
        ajax: "/akademik/siswa_monitoring/monitoring_tes",
        paramData: function(d) {
            if (self.$route.params.id) {
                d.siswa_id = self.$route.params.id;
            } else {
                d.siswa_id = 0;
            }
        },
        scrollX: true,
        selectedRowClass: "",
        frame: true,
        displayLength: 100,
        lengthChange: false,
        filter: false,
        sort: false,
        // columns: [
        // ],
        rowCallback: function(row, data) {},
    });
	},
}
</script>